<template>
  <rules-table
    :headers="headers"
    :rtb_standards="rtb_standards"
    @getRtbId="rtbRuleDelete($event)"
  />
</template>

<script>
import RulesTable from "../../../components/Tables/RulesTable.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Rule Name",
        align: "start",
        sortable: false,
        value: "name",
        width: "300px",
      },
      { text: "Operator", value: "operator", width: "130px" },
      { text: "Operand", value: "operand", width: "500px" },
      {
        text: "Options",
        value: "frequency",
        width: "130px",
        align: "end",
      },
    ],
    error: null,
  }),
  components: {
    RulesTable,
  },
  computed: {
    rtb_standards() {
      return this.$store.state.dsp.rtb_standards;
    },
  },
  async mounted() {
    await this.fetchRtbStds();
  },
  methods: {
    async rtbRuleDelete(rtbId) {
      this.rule = rtbId;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/deleteRule", this.rule);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "RTB Rule deleted successfully!",
          group: "success",
        });
        await this.fetchRtbStds();
      } catch (error) {
        this.error = error;
      }
    },
    
    async fetchRtbStds() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getStandards");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>