<template>
  <v-card id="rtb_rules" outlined>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-btn depressed color="primary" to="/audience/rules/add_rule"
            >Add RTB Rule
          </v-btn>
        </v-col>
        <v-col cols="9">
          <div class="d-flex align-center justify-end">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              class="shrink mr-2"
            ></v-text-field>
            <v-btn outlined color="primary" class="mr-2">RTB Campaign rules</v-btn>
            <v-btn outlined color="primary"> Banner RTB rules</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :items="rtb_standards"
      :search="search"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: '',
            params: {
              id: item.id,
            },
          }"
        >
          <h5>{{ item.name }}</h5>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.operator="{ item }">
        <span v-for="(Operator, i) in item.operator" :key="i">
          <span>{{ Operator }}</span>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.operand="{ item }">
        <!-- {{ item.operand.split(",") }} -->
        <ul v-for="(operand, i) in item.operand.split(',')" :key="i">
          <li v-if="operand">{{ operand }}</li>
        </ul>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.frequency="{ item }">
        <v-btn small class="mr-5" color="blue" icon @click="updateRtbRule(item.id)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn small color="red" icon @click="deleteRtbRule(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions
      class="d-flex justify-space-between align-center pa-4"
      v-if="rtb_standards.length"
    >
      <div class="caption mr-3">Showing page {{ page }}</div>
      <v-pagination
        v-model="page"
        :length="pageCount"
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        color="primary"
        class="custom"
        total-visible="10"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),
  props: {
    headers: Array,
    rtb_standards: Array,
  },
  methods: {
    updateRtbRule(rtbId) {
      this.$router.push({
        name: "edit-rtb-rule",
        params: {
          id: rtbId,
        },
      });
    },
    deleteRtbRule(rtbId) {
      this.$emit("getRtbId", rtbId);
    },
  },
};
</script>

<style scoped>
#targeting {
  margin: 2.5rem 0;
}
</style>
